<template>
  <div>
    <v-container primary white--text :fluid="true" text-center>
      <v-layout wrap>
        <v-flex xs12>
          <div>
            <h2>{{ $t("page_title") }}</h2>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-row dense>
        <v-col
          v-for="article in articles"
          :key="article.title"
          md="4"
          sm="6"
          cols="12"
        >
          <v-card
            class="article-card"
            :to="{ path: locale.base + '/blog/' + article.slug }"
          >
            <v-img
              :lazy-src="article.image_url"
              :src="article.image_url"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
              height="200px"
            >
            </v-img>

            <v-card-title class="card-title"> {{ article.title }}</v-card-title>
            <v-card-text
              v-html="truncateString(article.body, 160)"
            ></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "page_title": "Blog"
  },
  "ja": {
    "page_title": "ブログ"
  }
}
</i18n>

<script>
import store from "store";
import api from "services/api";

export default {
  components: {},
  data: () => ({
    articles: []
  }),
  created() {
    this.getArticles();
  },
  methods: {
    getArticles() {
      api.getArticles().then(resp => {
        this.articles = resp.data;
      });
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "...";
    }
  },
  computed: {
    locale() {
      return store.getters.locale;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}

.article-card {
  &:hover {
    background-color: var(--v-gray_lighter-base);
    .card-title {
      color: var(--v-secondary-base);
    }
  }
}
</style>
